<template>
  <v-expansion-panel-header color="#f5f5f5" class="pa-0 px-4">
    <div class="d-flex align-center justify-space-between">
      <!-- LEFT SIDE ACTIONS -->
      <div class="d-flex align-center">
        <v-icon :disabled="isLoading" class="menu-drag handle mr-4">
          mdi-menu
        </v-icon>
        <v-switch
          v-model="localRule.isActive"
          :disabled="isLoading"
          @click.stop="$emit('switchRule')"
          dense
          flat
          class="mr-4"
        ></v-switch>

        <span class="font-weight-bold mr-2">{{ index + 1 }} -</span>

        <h4>{{ localRule.description }}</h4>

        <Prd-loading-circular v-if="isLoading" class="ml-6" />
      </div>

      <!-- RIGHT SIDE ACTIONS -->
      <div class="d-flex align-center mr-4">
        <div
          :class="localRule.isActive ? 'active-color' : 'inactive-color'"
          class="rules-status active-color mr-8"
        >
          {{ $i18n.t("TXT_ACTIVE") }}
        </div>

        <v-icon
          v-if="isGroupRules"
          :disabled="isLoading"
          @click.stop="$emit('duplicateRule')"
          color="#6792F8"
          class="mr-1"
          size="26px"
        >
          mdi-plus-box-multiple
        </v-icon>

        <Delete-Rule-Modal
          :rule="rule"
          :isGroupRules="isGroupRules"
          @ruleDeleted="$emit('ruleDeleted')"
          @removeRuleFromList="$emit('removeRuleFromList', rule)"
        />
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import DeleteRuleModal from "./delete-rule-modal.vue";
export default {
  components: { PrdLoadingCircular, DeleteRuleModal },

  props: {
    rule: {
      type: Object,
    },
    index: {
      type: Number,
    },
    isLoading: {
      type: Boolean,
    },
    isGroupRules: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    localRule: null,
  }),

  watch: {
    rule: {
      handler(value) {
        this.localRule = structuredClone(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-drag {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.rules-status {
  display: flex;
  width: 70px;
  padding: 4px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: white;
  border-radius: 4px;
}

.active-color {
  background-color: #1d8527;
}

.inactive-color {
  background-color: #bab8b8;
}
</style>
