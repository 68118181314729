<template>
  <div class="mt-4">
    <v-row no-gutters class="mb-4">
      <v-col cols="12" md="3">
        <Prd-combo-box
          v-model="model.TypeReference"
          :items="TypeReferenceItems"
          :title="$t('TXT_PRICE_TO_BE_USED')"
          placeholder="TXT_SELECT"
        />
      </v-col>
    </v-row>

    <v-row no-gutters align="start">
      <v-checkbox
        v-model="model.BlockIfNull"
        :label="$t('TXT_BLOCK_NO_MARKET_PRICE')"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
      <Prd-text-field
        v-model="model.MinBlockMarket"
        :title="$t('TXT_MIN_VALUE_TO_BLOCK')"
        :disabled="!model.BlockIfNull"
        :filled="!model.BlockIfNull"
        type="number"
      />
    </v-row>

    <v-row no-gutters align="end">
      <v-col cols="auto">
        <Prd-autocomplete-chip
          :originalValue="model.competitors"
          :title="$t('TXT_COMPETITOR') + ':'"
          :items="sellerList"
          :itemText="'value'"
          :externalDisabled="showAlert || sellerListLoading"
          :isLoading="sellerListLoading"
          @updates="model.competitors = $event"
          @searchInput="searchValue = $event"
        />
      </v-col>
      <v-col cols="auto" class="pb-2">
        <Prd-tooltip-informations
          v-if="showAlert && !sellerListLoading"
          class="mt-4"
          :icon="'mdi-alert'"
          :sizeIcon="25"
          :colorIcon="'warning'"
          :text="$t('TXT_INFO_COMPETITORS')"
        />
      </v-col>
      <v-col class="ml-2 mt-2">
        <Prd-btn
          :title="$t('TXT_APPROVE_ALL')"
          :disabled="showAlert || !hasSellers"
          @click="addFilterSellers()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdAutocompleteChip from "@/components/common/prd-autocomplete-chip.vue";
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  props: ["values"],
  components: {
    PrdComboBox,
    PrdTextField,
    PrdAutocompleteChip,
    PrdTooltipInformations,
    PrdBtn,
  },
  data() {
    return {
      model: {
        BlockIfNull: false,
        MinBlockMarket: null,
        TypeReference: {
          text: this.$i18n.t("TXT_LOWER_PRICE_COMPETITOR"),
          value: 0,
        },
        competitors: [],
      },

      TypeReferenceItems: [
        {
          text: this.$i18n.t("TXT_LOWER_PRICE_COMPETITOR"),
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_AVAREGE_PRICE_COMPETITOR"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_HIGHER_PRICE_COMPETITOR"),
          value: 2,
        },
      ],

      searchValue: null,
    };
  },

  computed: {
    sellerList() {
      return this.$store?.state?.pricingRuleV2.sellerList ?? [];
    },
    showAlert() {
      return !this.sellerList || this.sellerList.length == 0;
    },
    sellerListLoading() {
      return this.$store?.state?.pricingRuleV2?.sellerListLoading ?? false;
    },
    foundSellers() {
      return (
        this.sellerList.filter((competitor) =>
          competitor?.value
            ?.toLowerCase()
            .includes(this.searchValue?.toLowerCase())
        ) ?? []
      );
    },
    hasSellers() {
      return this.foundSellers.length > 0 && this.searchValue != "";
    },
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },

  methods: {
    updateConditions(inputs) {
      if (!inputs.BlockIfNull) this.model.MinBlockMarket = null;
      let formatedValue = [
        {
          stringValue: "TypeReference",
          decimalValue: inputs?.TypeReference?.value ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "BlockIfNull",
          booleanValue: inputs.BlockIfNull,
          decimalValue: 0,
        },
        {
          stringValue: "MinBlockMarket",
          decimalValue: inputs.MinBlockMarket
            ? parseFloat(inputs.MinBlockMarket)
            : 0,
          booleanValue: false,
        },
      ];

      const competitors = this.prepareCompetitorsForUpdateConditions(inputs);
      formatedValue.push(...competitors);

      this.$emit("updateConditionValues", { formatedValue, conditionType: 4 });
    },

    prepareCompetitorsForUpdateConditions(inputs) {
      const competitors = structuredClone(inputs.competitors);
      return competitors.map((comp) => ({
        stringValue: comp.key,
        decimalValue: 0,
        booleanValue: false,
      }));
    },

    setInputs(conditionList) {
      const TypeReference = conditionList.find(
        (condition) => condition.stringValue == "TypeReference"
      ).decimalValue;
      const TypeReferenceItem = this.TypeReferenceItems.find(
        (item) => item.value == TypeReference
      );
      this.model.TypeReference = TypeReferenceItem;

      const BlockIfNull = conditionList.find(
        (condition) => condition.stringValue == "BlockIfNull"
      ).booleanValue;
      this.model.BlockIfNull = BlockIfNull;

      const MinBlockMarket = conditionList.find(
        (condition) => condition.stringValue == "MinBlockMarket"
      ).decimalValue;
      this.model.MinBlockMarket = MinBlockMarket;

      this.model.competitors = this.setCompetitors(conditionList);
    },

    setCompetitors(conditionList) {
      const filteredCondition = conditionList.filter((condition) =>
        condition.stringValue.includes("SELLERNAME")
      );
      const cloneArray = structuredClone(filteredCondition);
      const competitors = cloneArray.reduce((acc, comp) => {
        const foundedItem = this.sellerList.find(
          (compItemList) => compItemList.key === comp.stringValue
        );
        if (foundedItem) {
          acc.push(foundedItem);
        }
        return acc;
      }, []);
      return competitors;
    },
    addFilterSellers() {
      this.model.competitors.push(...structuredClone(this.foundSellers));
      this.model.competitors = this.removeDuplicates(this.model.competitors, item => item.value)
      this.searchValue = null;
    },
    removeDuplicates(array, keyExtractor) {
      const seen = new Set();
      return array.filter((item) => {
        const key = keyExtractor(item);
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>
