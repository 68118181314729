<template>
  <div>
    <Prd-btn
      :title="$t('TXT_SEE_PRICING_CENARY')"
      :disabled="products.length <= 0"
      @click="modalOpen = true"
    />

    <v-dialog v-model="modalOpen" persistent :width="1200">
      <v-card class="pa-4">
        <v-row no-gutters justify="space-between" align="center">
          <h4 v-t="'TXT_PRICING_CENARY'"></h4>

          <div class="filter-row">
            <pricing-scenario-filter 
              v-if="filters.length"
              :filters="filters"
              :disabled="isLoading"
              @getErpPreview="getPricingScenario"
              class="mr-4" 
              ref="pricingScenarioFilter" 
            />

            <v-icon
              :color="$prdStyles('color-primary')"
              :disabled="loadingReport"
              @click="modalOpen = false"
              :class="VEM ? 'mt-6' : ''"
              >mdi-close</v-icon
            >
          </div>
        </v-row>

        <div class="main-scenario">
          <div
            v-for="(categorie, index) in categories"
            :key="index"
            class="scenario-column"
          >
            <v-row no-gutters align="center" class="my-4">
              <v-icon small left :color="$prdStyles('color-primary')">{{
                categorie.icon
              }}</v-icon>
              <h4>{{ categorie.columnTitle }}</h4>
            </v-row>
            <v-card
              v-for="(value, index) in categorie.values"
              :key="index"
              class="scenario-item pa-2 pb-6"
            >
              <v-row v-if="value.isKey" no-gutters align="center" class="mb-4">
                <label
                  v-t="$t('TXT_CHANGES_IN', { STORE: '' })"
                  class="font-weight-bold mr-2"
                ></label>
                <v-skeleton-loader type="button" :height="15" />
              </v-row>
              <label v-else class="font-weight-bold mb-4">{{
                value.title
              }}</label>

              <v-skeleton-loader v-if="isLoading" type="button" :height="15" />
              <span v-else class="color-destaq">{{ value.value }}</span>
            </v-card>
          </div>
        </div>

        <div v-if="VEM" class="vem-div mt-4">
          <v-card style="width: 25%" class="scenario-item pa-2 pb-6">
            <label
              v-t="'TXT_MONTH_VARIATION'"
              class="font-weight-bold mb-4"
            ></label>
            <v-skeleton-loader v-if="isLoading" type="button" :height="15" />
            <span v-else class="color-destaq">{{
              formatMonetary(vemSallesMonthProjection)
            }}</span>
          </v-card>
          <v-card style="width: 25%" class="scenario-item pa-2 pb-6">
            <label
              v-t="'TXT_MONTH_VARIATION'"
              class="font-weight-bold mb-4"
            ></label>
            <v-skeleton-loader v-if="isLoading" type="button" :height="15" />
            <span v-else class="color-destaq">{{
              formatMonetary(vemProfitMonthProjection)
            }}</span>
          </v-card>
        </div>

        <v-row no-gutters justify="end" class="mt-4">
          <extract-report-dialog :isLoading="isLoading"/>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricer/product-list.js";
const Service = new service();
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
import { formatNumberPercentage } from "@/utils/format-numbers";
import ExtractReportDialog from "./extract-report-dialog.vue";
import PricingScenarioFilter from "./pricing-scenario-filter.vue";

export default {
  props: ["products"],
  components: { PrdBtn, ExtractReportDialog, PricingScenarioFilter },
  data() {
    return {
      modalOpen: false,
      isLoading: false,
      loadingReport: false,
      categories: [
        {
          columnTitle: this.$i18n.t("TXT_CHANGES").toUpperCase(),
          icon: "mdi-swap-horizontal",
          values: [
            { title: this.$i18n.t("TXT_APPROVAL_CHANGES"), value: null },
            { title: this.$i18n.t("TXT_STORE_CHANGES"), value: "" },
            {
              title: `${this.$i18n.t("TXT_CHANGES_IN", {
                STORE: "-",
              })}`,
              value: "",
              isKey: true,
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_BILLING_VARIANTION").toUpperCase(),
          icon: "mdi-tag-outline",
          values: [
            { title: this.$i18n.t("TXT_BILLING_ACTUAL_PRICE"), value: null },
            {
              title: this.$i18n.t("TXT_REVENUE_PROJECTION_AFTER_AI"),
              value: null,
            },
            { title: this.$i18n.t("TXT_DAILY_VARIATION"), value: null },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_PROFIT_VARIATION_LOWER").toUpperCase(),
          icon: "mdi-currency-usd",
          values: [
            { title: this.$i18n.t("TXT_PROFIT_BRUT_ACTUAL"), value: null },
            { title: this.$i18n.t("TXT_PROFIT_APPROVAL"), value: null },
            { title: this.$i18n.t("TXT_PROFIT_VARIATION_LOWER"), value: null },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_MARGIN_VARIATION_LOWER").toUpperCase(),
          icon: "mdi-percent",
          values: [
            { title: this.$i18n.t("TXT_ACTUAL_MARGIN"), value: null },
            { title: this.$i18n.t("TXT_ACTUAL_MARGIN_APPROVAL"), value: null },
            { title: this.$i18n.t("TXT_MARGIN_VARIATION_LOWER"), value: null },
          ],
        },
      ],
      filters: [],

      vemSallesMonthProjection: null,
      vemProfitMonthProjection: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    VEM() {
      return this.idCompany == 3026 ? true : false;
    },
    idPriceGroup() {
      return this.$store?.getters?.idPriceGroup ?? null;
    },
  },

  watch: {
    modalOpen: {
      handler(value) {
        if (value) this.getInitialData();
      },
    },
  },

  methods: {
    formatMonetary,
    formatPercentage,
    formatNumberPercentage,

    async getPricingScenario(field = "", value = "") {
      this.isLoading = true;

      try {
        let response = await Service.getPricingModal(
          this.idCompany,
          this.idPriceGroup,
          field,
          value
        );
        this.treatScenaryData(response);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_ERROR"));
        this.modalOpen = false;
      }

      this.isLoading = false;
    },
    async getInitialData() {
      console.log('getting initial data')
      this.isLoading = true;

      try {
        const [scenaryResponse, filtersResponse] = await Promise.all([
          Service.getPricingModal(this.idCompany, this.idPriceGroup, "", ""),
          Service.getPricingModalFilters(this.idCompany, this.idPriceGroup).catch((error) => {
            console.log(error);
            this.$store.commit("snackbarV2/set", {
              message: "Não foi possível carregar os filtros.",
              type: "error",
            });
            return null;
          }),
        ]);
        this.treatScenaryData(scenaryResponse);
        this.filters = filtersResponse?.data?.result ?? [];
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
        this.modalOpen = false;
      } finally {
        this.isLoading = false;
      }
    },

    treatScenaryData(scenaryInfoResponse) {
      let result = scenaryInfoResponse?.data?.result ?? null;
      if (result) {
        if (this.VEM) this.categories = this.dataToVem(result);
        else this.categories = this.dataToCategories(result);
      }
    },
    dataToVem(data) {
      this.vemSallesMonthProjection = data.saleVariation_SaleVariation * 30;
      this.vemProfitMonthProjection = data.profitVariation_ProfitVariation * 30;

      return [
        {
          columnTitle: this.$i18n.t("TXT_CHANGES").toUpperCase(),
          icon: "$arrow2",
          values: [
            {
              title: this.$i18n.t("TXT_TOTAL_OF_ITEMS"),
              value: data.changes_TotalCount,
            },
            {
              title: this.$i18n.t("TXT_ITEMS_RULES_APROVED"),
              value: data.changes_ChangesApproved,
            },
            {
              title: this.$i18n.t("TXT_PRICE_IA_SUGGESTED"),
              value: data.changes_ApprovedSuggested,
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_BILLING_VARIANTION").toUpperCase(),
          icon: "$tag",
          values: [
            {
              title: this.$i18n.t("TXT_BILLING_ACTUAL_PRICE"),
              value: this.formatMonetary(data.saleVariation_SaleCurrentPrice),
            },
            {
              title: this.$i18n.t("TXT_REVENUE_PROJECTION_AFTER_AI"),
              value: this.formatMonetary(data.saleVariation_SaleApprovedPrice),
            },
            {
              title: this.$i18n.t("TXT_DAILY_VARIATION"),
              value: `${this.formatPercentage(
                data.saleVariation_SaleVariation
              )} (${this.calculateVariationInPercentage(
                data.saleVariation_SaleVariation,
                data.saleVariation_SaleApprovedPrice
              )})`,
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_PROFIT_VARIATION"),
          icon: "$dolar",
          values: [
            {
              title: this.$i18n.t("TXT_SALLES_PROFIT"),
              value: this.formatMonetary(
                data.profitVariation_SaleCurrentProfit
              ),
            },
            {
              title: this.$i18n.t("TXT_SALLES_PROFIT_AFTER_IA"),
              value: this.formatMonetary(
                data.profitVariation_SaleApprovedProfit
              ),
            },
            {
              title: this.$i18n.t("TXT_DAILY_VARIATION"),
              value: `${this.formatMonetary(
                data.profitVariation_ProfitVariation
              )} (${this.calculateVariationInPercentage(
                data.profitVariation_ProfitVariation,
                data.profitVariation_SaleApprovedProfit
              )})`,
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_MARGIN_VARIATION"),
          icon: "$percent",
          values: [
            {
              title: this.$i18n.t("TXT_ACTUAL_MARGIN"),
              value: formatPercentage(data.marginVariation_SaleMargin),
            },
            {
              title: this.$i18n.t("TXT_ACTUAL_MARGIN_APPROVAL"),
              value: formatPercentage(data.marginVariation_SaleMarginApproved),
            },
            {
              title: this.$i18n.t("TXT_VARIATION"),
              value: formatPercentage(data.marginVariation_MarginVariation),
            },
          ],
        },
      ];
    },

    dataToCategories(data) {
      return [
        {
          columnTitle: this.$i18n.t("TXT_CHANGES").toUpperCase(),
          icon: "mdi-swap-horizontal",
          values: [
            {
              title: this.$i18n.t("TXT_APPROVAL_CHANGES"),
              value: data.changes_ChangesApproved,
            },
            {
              title: this.$i18n.t("TXT_STORE_CHANGES"),
              value: data.changes_ShopMoreChanges
                ? data.changes_ShopMoreChanges
                : "-",
            },
            {
              title: `${this.$i18n.t("TXT_CHANGES_IN", {
                STORE: data.changes_ShopMoreChanges
                  ? data.changes_ShopMoreChanges
                  : "-",
              })}`,
              value: data.changes_ChangesShopMoreChanges
                ? data.changes_ChangesShopMoreChanges
                : "-",
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_BILLING_VARIANTION").toUpperCase(),
          icon: "mdi-tag-outline",
          values: [
            {
              title: this.$i18n.t("TXT_BILLING_ACTUAL_PRICE"),
              value: this.formatMonetary(data.saleVariation_SaleCurrentPrice),
            },
            {
              title: this.$i18n.t("TXT_REVENUE_PROJECTION_AFTER_AI"),
              value: this.formatMonetary(data.saleVariation_SaleApprovedPrice),
            },
            {
              title: this.$i18n.t("TXT_DAILY_VARIATION"),
              value: this.formatMonetary(data.saleVariation_SaleVariation),
            },
          ],
        },
        {
          columnTitle: this.$i18n.t("TXT_PROFIT_VARIATION_LOWER").toUpperCase(),
          icon: "mdi-currency-usd",
          values: [
            {
              title: this.$i18n.t("TXT_PROFIT_BRUT_ACTUAL"),
              value: this.formatMonetary(
                data.profitVariation_SaleCurrentProfit
              ),
            },
            {
              title: this.$i18n.t("TXT_PROFIT_APPROVAL"),
              value: this.formatMonetary(
                data.profitVariation_SaleApprovedProfit
              ),
            },
            {
              title: this.$i18n.t("TXT_PROFIT_VARIATION_LOWER"),
              value: this.formatMonetary(data.profitVariation_ProfitVariation),
            },
          ],
        },
        {
          icon: "mdi-percent",
          columnTitle: this.$i18n.t("TXT_MARGIN_VARIATION_LOWER").toUpperCase(),
          values: [
            {
              title: this.$i18n.t("TXT_ACTUAL_MARGIN"),
              value: this.formatPercentage(data.marginVariation_SaleMargin),
            },
            {
              title: this.$i18n.t("TXT_ACTUAL_MARGIN_APPROVAL"),
              value: this.formatPercentage(
                data.marginVariation_SaleMarginApproved
              ),
            },
            {
              title: this.$i18n.t("TXT_MARGIN_VARIATION_LOWER"),
              value: `${data.marginVariation_MarginVariation >= 0 ? "+" : ""} 
                        ${this.formatPercentage(
                          data.marginVariation_MarginVariation
                        )}`,
            },
          ],
        },
      ];
    },

    calculateVariationInPercentage(value, relational) {
      return formatNumberPercentage((value / relational) * 100, {
        alwaysRenderSign: true,
        fractionLength: 2,
      });
    },

    async extractReport() {
      this.loadingReport = true;
      try {
        let response = await Service.extractReport(
          this.idCompany,
          this.idPriceGroup
        );
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `precificacao_cenario.xlsx`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
      }

      this.loadingReport = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.main-scenario {
  display: flex;
  gap: 1rem;

  .scenario-column {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .scenario-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 12px;
    }

    .color-destaq {
      color: $brand-color-primary-pure;
    }
  }
}

.vem-div {
  display: flex;
  justify-content: center;
  gap: 1rem;
  .scenario-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 12px;
    }

    .color-destaq {
      color: $brand-color-primary-pure;
    }
  }
}
</style>