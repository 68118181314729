<template>
  <div>
    <v-row no-gutters>
      <v-col
        v-for="filter in regionFilters"
        :key="filter.valueType"
        :cols="colsSize"
        :class="filter.classes"
      >
        <Prd-combo-box
          v-model="filter.selected"
          :items="filter.items"
          :title="filter.label"
          :label="$t('TXT_SELECT')"
          :disabled="filter.isLoading"
          :loading="filter.isLoading"
          multiple
          deletable-chips
          small-chips
          clearable
          @hasSelections="handleChange"
          @onFocus="$emit('toogleUnlockSeeResults', true)"
          @onBlur="$emit('toogleUnlockSeeResults', false)"
        />
      </v-col>
    </v-row>

    <Prd-combo-box
      v-model="productFilter.selected"
      :items="productFilter.items"
      :title="productFilter.label"
      :label="$t('TXT_SELECT')"
      :disabled="
        productFilter.isLoading &&
        currentKeyForPagination != productFilter.value
      "
      :loading="productFilter.isLoading"
      :searchDelay="2000"
      :ofsetItem="10"
      :no-filter="productFilter.usePagination"
      multiple
      deletable-chips
      small-chips
      clearable
      @onFocus="$emit('toogleUnlockSeeResults', true)"
      @onBlur="$emit('toogleUnlockSeeResults', false)"
      @loadMoreItems="loadMoreItems(productFilter)"
      @hasSelections="handleChange"
      @searchInput="handleSearch($event, productFilter)"
      @change="handleSelection(productFilter, $event)"
      class="my-6"
    />

    <v-row no-gutters>
      <v-col
        v-for="filter in defaultFilters"
        :key="filter.valueType"
        :cols="colsSize"
        :class="filter.classes"
      >
        <Prd-combo-box
          v-model="filter.selected"
          :items="filter.items"
          :title="filter.label"
          :label="$t('TXT_SELECT')"
          :disabled="
            filter.isLoading && currentKeyForPagination != filter.value
          "
          :loading="filter.isLoading"
          :searchDelay="2000"
          :ofsetItem="10"
          :no-filter="filter.usePagination"
          multiple
          deletable-chips
          small-chips
          clearable
          @hasSelections="handleChange"
          @searchInput="handleSearch($event, filter)"
          @onFocus="$emit('toogleUnlockSeeResults', true)"
          @onBlur="$emit('toogleUnlockSeeResults', false)"
          @loadMoreItems="filter.usePagination && loadMoreItems(filter)"
          @change="handleSelection(filter, $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: {
    PrdComboBox,
  },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
    savedFilter: {
      type: Object,
    },
    paginationTake: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      filters: [
        {
          label: this.$i18n.t("TXT_REGION"),
          value: "regions",
          valueType: 1,
          items: [],
          classes: "pr-4",
          selected: [],
          isLoading: false,
          usePagination: false,
          page: null,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_STATE"),
          value: "states",
          valueType: 2,
          items: [],
          classes: "pr-4",
          selected: [],
          isLoading: false,
          usePagination: false,
          page: null,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_SEGMENT"),
          value: "segments",
          valueType: 3,
          items: [],
          classes: "",
          selected: [],
          isLoading: false,
          usePagination: false,
          page: null,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_PRODUCTS"),
          value: "products",
          valueType: 1000,
          items: [],
          classes: "",
          selected: [],
          isLoading: false,
          usePagination: true,
          page: 1,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_CATEGORY"),
          value: "categories",
          valueType: 4,
          items: [],
          classes: "pr-4",
          selected: [],
          isLoading: false,
          usePagination: false,
          page: null,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_ORIGIN"),
          value: "origins",
          valueType: 5,
          items: [],
          classes: "pr-4",
          selected: [],
          isLoading: false,
          usePagination: false,
          page: null,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_MANUFACTURER"),
          value: "sellers",
          valueType: 6,
          items: [],
          classes: "pr-4",
          selected: [],
          isLoading: false,
          usePagination: true,
          page: 1,
          search: "",
          hasReachedTheEnd: true,
        },
        {
          label: this.$i18n.t("TXT_BRAND"),
          value: "brands",
          valueType: 7,
          items: [],
          classes: "",
          selected: [],
          isLoading: false,
          usePagination: true,
          page: 1,
          search: "",
          hasReachedTheEnd: true,
        },
      ],
      currentKeyForPagination: null,
    };
  },

  computed: {
    colsSize() {
      return 12 / this.filters.length;
    },
    regionFilters() {
      return this.filters.slice(0, 3);
    },
    productFilter() {
      return this.filters.slice(3, 4)[0];
    },
    defaultFilters() {
      return this.filters.slice(4, 8);
    },
  },
  watch: {
    savedFilter: {
      handler(value) {
        this.populateFiltersBySavedFilter(value);
      },
      deep: true,
      immediate: true,
    },
    externalLoading: {
      handler(value) {
        this.filters.forEach((filter) => (filter.isLoading = value));
      },
      immediate: true,
    },
  },
  methods: {
    cleanSelection(isExternalCall) {
      this.filters.forEach((filter) => {
        filter.selected = [];
        if (isExternalCall) {
          filter.items = [];
          filter.hasReachedTheEnd = false;
        }
      });
      this.currentKeyForPagination = null;
    },
    getSelections() {
      let selections = {};

      this.filters.forEach((filter) => {
        selections[filter.value] = filter.selected;
      });

      this.setPaginationProperties(selections);

      return selections;
    },

    setPaginationProperties(selections) {
      const filter = this.filters.find(
        (filter) => filter.value == this.currentKeyForPagination
      );

      if (filter) {
        selections.page = filter.page;
        selections.isProductPagination = filter.value == "products";
        selections.isSellerPagination = filter.value == "sellers";
        selections.isBrandPagination = filter.value == "brands";
        selections.productName =
          filter.value == "products" ? filter.search : null;

        selections.sellerName =
          filter.value == "sellers" ? filter.search : null;

        selections.brandName = filter.value == "brands" ? filter.search : null;
        selections.currentKeyForPagination = this.currentKeyForPagination;
      }

      return selections;
    },
    populateFilters(items) {
      this.filters.forEach((filter) => {
        const currentItems = items[filter.value];
        if (Array.isArray(currentItems) && currentItems.length > 0) {
          if (filter.usePagination) {
            filter.items.push(...currentItems);
            filter.hasReachedTheEnd = currentItems.length < this.paginationTake;
          } else filter.items = currentItems;
        }
      });
    },

    removeNonExistentSelectedItem(availableItems) {
      const keys = Object.keys(availableItems);

      keys.forEach((key) => {
        const filter = this.filters.find((filter) => filter.value == key);
        if (filter) {
          filter.selected = filter.selected.filter((item) =>
            filter.items.includes(item)
          );
        }
      });
    },

    populateFiltersBySavedFilter(data) {
      if (!data) return;
      this.cleanSelection(false);

      this.filters.forEach((currentFilter) => {
        const saved = data.values.filter(
          (save) => save.valueType == currentFilter.valueType
        );
        if (saved.length > 0) {
          currentFilter.selected = currentFilter.items.filter((item) =>
            saved.find((save) => save.value == item)
          );
        }
      });
    },

    handleChange() {
      this.filters.forEach((filter) => {
        filter.items = [];
        filter.page = 1;
        filter.hasReachedTheEnd = false;
      });
      this.currentKeyForPagination = null;
      this.$emit("handleChange");
    },

    loadMoreItems(filter) {
      if (!filter.hasReachedTheEnd || filter.isLoading) {
        this.currentKeyForPagination = filter.value;
        filter.page++;
        this.$emit("handleChange");
      }
    },

    handleSearch(value, filter) {
      if (filter.isLoading) return;

      filter.page = 1;
      filter.items = [];
      filter.search = value;
      this.currentKeyForPagination = filter.value;

      this.$emit("handleChange");
    },

    toggleLoading(key, value) {
      const filter = this.filters.find((filter) => filter.value == key);

      if (filter) filter.isLoading = value;
    },

    anyFilterisLoading() {
      return this.filters.some((filter) => filter.isLoading);
    },

    handleSelection(filter, values) {
      filter.selected = values;
      filter.page = 1;
    },
  },
};
</script>
