import { render, staticRenderFns } from "./title.vue?vue&type=template&id=2203eb6d&scoped=true"
import script from "./title.vue?vue&type=script&lang=js"
export * from "./title.vue?vue&type=script&lang=js"
import style0 from "./title.vue?vue&type=style&index=0&id=2203eb6d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2203eb6d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBreadcrumbs,VIcon,VRow})
