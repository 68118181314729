<template>
  <v-card class="pa-4">
    <v-row no-gutters align="end">
      <v-col cols="auto" class="mr-6">
        <Prd-date-picker
          :modelValue="selectedDate"
          @input="selectedDate = $event"
          @hasValidDate="hasValidDate = $event"
        />
      </v-col>
      <v-col cols="4" v-if="false">
        <Saved-filters
          v-model="savedFilter"
          ref="handlerSavedFilters"
          :externalDisabled="isLoading"
          @change="savedFilter = $event"
        />
      </v-col>
    </v-row>

    <h4 class="my-6">{{ $t("TXT_FILTERS") }}</h4>

    <div class="filter-label">
      <span>{{ this.$i18n.t("TXT_PRODUCT_FILTERS") }}</span>
    </div>

    <Default-filters
      :ref="refs[0]"
      :externalLoading="isLoading"
      :savedFilter="savedFilter"
      :paginationTake="paginationTake"
      @handleChange="getFilters"
      @toogleUnlockSeeResults="toogleUnlockSeeResults"
      class="mb-4"
    />

    <Machinery-filters
      v-if="hasMachineryResource"
      :ref="refs[1]"
      :externalLoading="isLoading"
      :savedFilter="savedFilter"
      @handleChange="getFilters"
      @toogleUnlockSeeResults="toogleUnlockSeeResults"
      class="mb-4"
    />

    <v-row no-gutters justify="space-between" class="my-6">
      <v-col cols="auto">
        <Delete-filters-modal
          v-if="savedFilter"
          :savedFilter="savedFilter"
          @cleanFilters="cleanFilters"
          @reorganizeSavedFilters="$refs.handlerSavedFilters.reorganize($event)"
        />
      </v-col>

      <v-col cols="auto" v-if="false">
        <v-row no-gutters justify="end">
          <Prd-depressed-button
            v-if="showCleanButton"
            :title="$t('TXT_CLEAR_FILTERS')"
            :leftIcon="'mdi-close'"
            :class="cleanButtonClassess"
            :disabled="haveSomeLoading"
            @click="cleanFilters"
          />
          <Save-filters-modal
            v-if="hasSelectedFilters"
            :selections="selections"
            @addNewSavedFilter="addNewSavedFilter"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="end" align="center" class="gap-4">
      <Prd-btn
        :title="$t('TXT_SEE_RESULTS')"
        :disabled="seeResultsButtonDisabled"
        @click="prepareRequestForComponents"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdDatePicker from "@/components/common/prd-date-picker.vue";
import SavedFilters from "./components/handler-save-filters/saved-filters.vue";
import DefaultFilters from "./components/filters/default-filters.vue";
import MachineryFilters from "./components/filters/machinery-filters.vue";
import SaveFiltersModal from "./components/handler-save-filters/save-filters-modal.vue";
import DeleteFiltersModal from "./components/handler-save-filters/delete-filters-modal.vue";
import PrdDepressedButton from "@/components/common/prd-depressed-button.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import FiltersPredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  components: {
    PrdDatePicker,
    SavedFilters,
    DefaultFilters,
    MachineryFilters,
    SaveFiltersModal,
    DeleteFiltersModal,
    PrdDepressedButton,
    PrdBtn,
  },
  data() {
    return {
      selectedDate: [],
      savedFilter: null,
      refs: ["defaultFilters", "machineryFilters"],
      isLoading: false,
      service: new FiltersPredimonitorService(),
      areThereSelections: false,
      paginationTake: 100,
      hasValidDate: false,
    };
  },
  computed: {
    selections() {
      return this.getSelections();
    },
    hasSelectedFilters() {
      const ignoredKeys = [
        "date",
        "page",
        "take",
        "isProductPagination",
        "isSellerPagination",
        "isBrandPagination",
        "productName",
        "sellerName",
        "brandName",
        "currentKeyForPagination",
      ];

      return Object.entries(this.selections).some(([key, value]) => {
        if (ignoredKeys.includes(key)) return false;

        if (key === "hourMeter" || key === "kilometers") {
          return value.min !== null || value.max !== null;
        }

        return Array.isArray(value) ? value.length > 0 : value && value !== "";
      });
    },

    hasMachineryResource() {
      return this.hasResource("marketplace.machinery");
    },
    showCleanButton() {
      return this.hasSelectedFilters || this.savedFilter != null;
    },
    cleanButtonClassess() {
      return this.hasSelectedFilters ? "mr-4" : "";
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isCost() {
      return this.$route.query.type?.includes("cost") ?? false;
    },
    VEM() {
      return this.idCompany == 3026;
    },
    seeResultsButtonDisabled() {
      return (
        !this.hasSelectedFilters ||
        this.areThereSelections ||
        !this.hasValidDate
      );
    },
    haveSomeLoading() {
      return this.isLoading || this.$refs?.[this.refs[0]]?.anyFilterisLoading();
    },
  },
  watch: {
    savedFilter: {
      handler(value) {
        if (value)
          setTimeout(() => {
            this.getFilters();
          }, 350);
      },
    },
    isCost: {
      handler() {
        this.cleanFilters();
      },
    },
    selections: {
      handler(value) {
        this.hasValidDate = value?.date?.start && value?.date?.end;
      },
    },
  },
  methods: {
    addNewSavedFilter(filter) {
      this.$refs.handlerSavedFilters.addNewSavedFilter(filter);
      this.savedFilter = filter;
    },

    toogleUnlockSeeResults(value) {
      this.areThereSelections = value;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    getBeforeSevenDays() {
      this.selectedDate = [];
      const currentDate = new Date();
      let beforeSevenDays = new Date();

      beforeSevenDays.setDate(currentDate.getDate() - 7);

      const formatDate = (date) => date.toISOString().split("T")[0];
      this.selectedDate = [
        formatDate(beforeSevenDays),
        formatDate(currentDate),
      ];
    },

    cleanFilters() {
      this.getBeforeSevenDays();
      this.savedFilter = null;
      this.refs.forEach((ref) => {
        if (this.$refs[ref]) this.$refs[ref].cleanSelection(true);
      });
      this.getFilters();
    },

    getSelections() {
      let selections = {
        date: {
          start: this.selectedDate[0] || null,
          end: this.selectedDate[1] || null,
        },
      };

      this.refs.forEach((ref) => {
        if (this.$refs[ref]) {
          selections = {
            ...selections,
            ...this.$refs[ref].getSelections(),
          };
        }
      });

      return selections;
    },

    async getFilters() {
      if (this.haveSomeLoading) return;
      const payload = this.prepareRequestForGetFilters();
      this.setLoading(true);
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Filtros",
        name: "Filtros",
        payload,
      });
      try {
        const response = await this.service.getFilters(payload);
        this.$store.commit("endRequest", { id: requestId, response });
        this.populateFilters(response?.data ?? null);
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, "Erro ao buscar filtros");
      } finally {
        this.setLoading(false);
      }
    },

    prepareRequestForGetFilters() {
      return {
        companyId: this.idCompany,
        productName: this.selections.productName ?? "",
        productNames: this.selections.products ?? [],
        isProductPagination: this.selections.isProductPagination ?? false,
        categories: this.selections.categories ?? [],
        models: this.selections.models ?? [],
        sellerName: this.selections.sellerName ?? "",
        sellers: this.selections.sellers ?? [],
        isSellerPagination: this.selections.isSellerPagination ?? false,
        brandName: this.selections.brandName ?? "",
        brands: this.selections.brands ?? [],
        isBrandPagination: this.selections.isBrandPagination ?? false,
        origins: this.selections.origins ?? [],
        states: this.selections.states ?? [],
        regions: this.selections.regions ?? [],
        segments: this.selections.segments ?? [],
        years: this.selections.years ?? [],
        isCost: this.isCost,
        page: this.selections.page ?? 1,
        take: this.paginationTake,
      };
    },

    setLoading(value) {
      const key = this.selections.currentKeyForPagination;

      if (key != null) this.$refs[this.refs[0]].toggleLoading(key, value);
      else this.isLoading = value;
    },

    populateFilters(items) {
      if (!items) return;
      this.refs.forEach((ref) => {
        if (this.$refs[ref]) {
          this.$refs[ref].populateFilters(items);
          this.$refs[ref].removeNonExistentSelectedItem(items);
        }
      });
    },

    prepareRequestForComponents() {
      const getJsonDate = (date) =>
        date ? new Date(date).toISOString() : null;

      const request = {
        companyId: this.idCompany,
        startDate: getJsonDate(this.selections.date.start),
        endDate: getJsonDate(this.selections.date.end),
        productNames: this.selections.products ?? [],
        categories: this.selections.categories ?? [],
        models: this.selections.models ?? [],
        sellers: this.selections.sellers ?? [],
        segments: this.selections.segments ?? [],
        brands: this.selections.brands ?? [],
        origins: this.selections.origins ?? [],
        regions: this.selections.regions ?? [],
        states: this.selections.states ?? [],
        years: this.selections.years ?? [],
        isCost: this.isCost,
        hourMeter: {
          min: this.selections?.hourMeter?.min || null,
          max: this.selections?.hourMeter?.max || null,
        },
        kilometers: {
          min: this.selections?.kilometers?.min || null,
          max: this.selections?.kilometers?.max || null,
        },
      };

      this.$store.commit("setLastRequest", request);
    },
    reStartComponent() {
      this.$store.commit("setLastRequest", null);
      this.selectedDate = [];
      this.savedFilter = null;
      this.isLoading = false;
      this.areThereSelections = false;
      this.hasValidDate = false;
      this.cleanFilters();
    },
  },

  mounted() {
    this.getBeforeSevenDays();
    this.getFilters();
  },
};
</script>

<style lang="scss" scoped>
.filter-label {
  padding: 4px 0px;
  margin-bottom: 16px;
  border-bottom: 0.5px solid #ccc;
  box-sizing: border-box;
  font-family: "Ubuntu", "Arial", "sans-serif";
  font-weight: bold !important;
  font-size: 14px !important;
}

.filter-label span {
  width: max-content;
  border-bottom: 3px solid $brand-color-secondary-pure;
  padding-bottom: 4px;
}
</style>
