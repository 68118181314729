<template>
  <v-card class="main-grid pa-3">
    <h4 class="mb-2">{{ $i18n.t("TXT_SUGGESTED_PRICE").toUpperCase() }}</h4>

    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <div class="d-flex flex-row">
          <p v-t="'TXT_MARGIN'"></p>
          <Prd-tooltip :text="$t('TXT_MARGIN_FORMULA')" :sizeIcon="16" />
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(adjustedMargin)">
          {{ switchSigns(adjustedMargin) }}
          {{ formatPercentage(adjustedMargin) }}
        </span>
      </div>

      <div class="value">
        <p v-t="'TXT_VARIATION_DEMAND'"></p>

        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(demandVariation)">
          {{ switchSigns(demandVariation) }}
          {{ formatDecimals(demandVariation, 2) }}
          <span
            v-if="demandVariationPercentage"
            :class="[switchColors(demandVariationPercentage), 'small']"
            >( {{ numberToPercentage(demandVariationPercentage) }} )</span
          >
        </span>
      </div>
    </div>

    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <p>IC</p>

        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(competitivenessIndex, true)">
          {{ formatPercentage(competitivenessIndex * 100) }}
        </span>
      </div>

      <div class="value">
        <p v-t="'TXT_CURVE'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(abcCurve, true)">{{ abcCurve }}</span>
      </div>
    </div>

    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <p v-t="'TXT_BILLING_VARIANTION_SHORT'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(saleVariation)">
          {{ formatMonetary2Version(saleVariation) }}
          <span
            v-if="saleVariationPercentage"
            :class="[switchColors(saleVariationPercentage), 'small']"
            >( {{ numberToPercentage(saleVariationPercentage) }} )</span
          >
        </span>
      </div>

      <div class="value">
        <p v-t="'TXT_VARIATION_PROFIT'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(profitVariation)">
          {{ formatMonetary2Version(profitVariation) }}
          <span
            v-if="profitVariationPercentage"
            :class="[switchColors(profitVariationPercentage), 'small']"
            >( {{ numberToPercentage(profitVariationPercentage) }} )</span
          >
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import service from "@/service/pricer/product-list.js";
const Service = new service();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import {
  formatMonetary,
  formatMonetary2Version,
} from "@/utils/format-toMonetary.js";
import {
  formatPercentage,
  numberToPercentage,
} from "@/utils/format-percentage.js";
import { formatDecimals } from "@/utils/format-decimals.js";
export default {
  components: { PrdLoadingCircular, PrdTooltip },
  props: ["idEnterprisePricesProjection"],
  data() {
    return {
      isLoading: false,
      loadingSkeleton: false,
      data: null,
    };
  },

  computed: {
    adjustedMargin() {
      return this.data?.adjustedMargin ?? "-";
    },
    demandVariation() {
      return this.data?.demandVariation ?? "-";
    },
    competitivenessIndex() {
      return this.data?.competitivenessIndex ?? "-";
    },
    abcCurve() {
      return this.data?.abcCurve ?? "-";
    },
    saleVariation() {
      return this.data?.saleVariation ?? "-";
    },
    profitVariation() {
      return this.data?.profitVariation ?? "-";
    },
    demandVariationPercentage() {
      return this.data?.demandVariationPercentage ?? false;
    },
    saleVariationPercentage() {
      return this.data?.saleVariationPercentage ?? false;
    },
    profitVariationPercentage() {
      return this.data?.profitVariationPercentage ?? false;
    },
  },

  methods: {
    formatMonetary,
    formatMonetary2Version,
    formatPercentage,
    formatDecimals,
    numberToPercentage,
    async getPricingInfo(isFromOptimizedPrice) {
      if (!isFromOptimizedPrice) this.isLoading = true;
      else this.loadingSkeleton = true;

      try {
        let response = await Service.getPricingInfo(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? null;
        this.$emit("setMarginToHeader", this.data?.AjustedMargin);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_SUGGESTED_PRICE"),
          type: "error",
        });
        this.data = null;
      }

      this.isLoading = false;
      this.loadingSkeleton = false;
    },

    switchSigns(value) {
      return value >= 0 ? "+" : "";
    },

    switchColors(value, isInfo) {
      if (isInfo) return "neutral-value";

      if (value >= 0) return "positive-value";
      if (value < 0) return "negative-value";
    },
  },

  created() {
    this.getPricingInfo();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-bottom: 8px;
}
span {
  font-size: 18px;
  font-weight: bold;

  .small {
    font-size: 14px;
  }

  @media screen and (max-width: 1480px) {
    display: flex;
    flex-direction: column;
  }
}
.main-grid {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex-basis: 1;
  position: relative;

  .value-box {
    display: flex;
    gap: 0.7rem;
    flex-grow: 1;

    .value {
      display: flex;
      flex-direction: column;
      width: 50%;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);

      padding: 8px;
    }
  }
}

.negative-value {
  color: $feedback-color-warning-pure !important;
}
.positive-value {
  color: $feedback-color-success-pure !important;
}
.neutral-value {
  color: $brand-color-primary-pure;
}
</style>
