<template>
  <span>
    <v-tooltip top :color="tooltipColor">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :small="true"
          :color="iconColor"
        >
          {{ icon }}
        </v-icon>
        <span
          :class="textClass"
          v-bind="attrs"
          v-on="on"
        >
          {{ text }}
        </span>
      </template>
      <span :style="{ color: tooltipTextColor }">{{ tooltipText }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: "TooltipIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: "#000", // Cor padrão do ícone
    },
    text: {
      type: String,
      required: true,
    },
    textClass: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipColor: {
      type: String,
      default: "#f5f5f5",
    },
    tooltipTextColor: {
      type: String,
      default: "black",
    },
    iconClass: {
      type: String,
      default: "",
    },
  },
};
</script>
