<template>
  <div>
    <v-row no-gutters align="center" class="mb-1">
      <span>{{ title }}</span>
      <tooltip-informations
        v-if="useTooltipInformation"
        :title="textTooltipInformation"
        :rightIcon="true"
      />
    </v-row>

    <v-combobox
      v-bind="$attrs"
      append-icon="mdi-chevron-down"
      :placeholder="personalizedText('placeholder')"
      :item-text="itemText"
      :label="personalizedText('label')"
      single-line
      outlined
      dense
      hide-details
      return-object
      item-value="value"
      @input="onInput"
      @change="change"
      @blur="onBlur"
      @focus="onFocus"
      @click:clear="onClearable"
      @update:search-input="searchInput"
    >
      <template v-slot:append-item>
        <div v-intersect="loadMoreItems"></div>
        <Prd-loading-circular v-if="isLoading" />
      </template>

      <template v-for="slot in parentSlots" #[slot]>
        <slot :name="slot" />
      </template>

      <template v-for="(index, name) in parentScopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import PrdLoadingCircular from "./prd-loading-circular.vue";

export default {
  name: "prdComboBox",
  components: { TooltipInformations, PrdLoadingCircular },
  data() {
    return {
      hasSelections: false,
      previousSelections: null,
      hasEmittedEndList: false,
      timer: null,
      isFirstSelectionAfterSearch: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    textTooltipInformation: {
      type: String,
    },
    useTooltipInformation: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    searchDelay: { type: Number, default: 1000 },
  },

  methods: {
    onInput(values) {
      this.$emit("input", values);
    },
    change(values) {
      this.$emit("change", values);
      this.hasSelections = true;
    },
    onFocus() {
      this.$emit("onFocus", this.$attrs.value);
      this.previousSelections = this.$attrs?.value ?? null;
    },
    onBlur() {
      this.$emit("onBlur", this.$attrs.value);

      if (
        this.hasSelections &&
        (JSON.stringify(this.$attrs.value) !==
          JSON.stringify(this.previousSelections) ||
          (this.$attrs.value.length == 0 &&
            this.previousSelections.length == 0))
      )
        this.$emit("hasSelections");

      this.hasSelections = false;
    },
    onClearable() {
      this.hasSelections = true;
      this.previousSelections = null;
    },
    loadMoreItems(entrie, observer, isIntersect) {
      if (
        !this.externalLoading &&
        !this.isLoading &&
        !this.hasEmittedEndList &&
        isIntersect
      ) {
        this.hasEmittedEndList = true;
        this.$emit("loadMoreItems");
      }
    },
    searchInput(event) {
      if (this.isFirstSelectionAfterSearch && event == null) {
        this.isFirstSelectionAfterSearch = false;
        return;
      }
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.$emit("searchInput", event);
        this.isFirstSelectionAfterSearch = true;
      }, this.searchDelay);
    },
    personalizedText(type) {
      if (this.$attrs.loading) return this.$i18n.t("TXT_LOADING_DATA");

      return this.$attrs?.items?.length == 0
        ? this.$i18n.t("TXT_NO_HAS_DATA")
        : this.$attrs?.[type] ?? this.$i18n.t("TXT_SELECT");
    },
  },
  computed: {
    parentSlots() {
      if (this.$slots) return Object.keys(this.$slots);
      return [];
    },
    parentScopedSlots() {
      if (this.$scopedSlots) return Object.keys(this.$scopedSlots);
      return [];
    },

    isMultiple() {
      return this.$attrs.multiple != undefined || this.$attrs.multiple;
    },
    isLoading() {
      return this.$attrs?.loading ?? false;
    },
    items() {
      return this.$attrs?.items ?? [];
    },
  },
  watch: {
    items() {
      this.hasEmittedEndList = false;
    },
  },
};
</script>

<style>
.v-select__selections {
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
