<template>
  <div v-if="isLoading">
    <v-skeleton-loader type="image,image" class="mb-4" />
    <v-skeleton-loader type="paragraph" />
    <v-skeleton-loader type="paragraph" />
  </div>
  <highcharts v-else ref="highchartsComponent" :options="completeOptions" />
</template>

<script>
import i18n from "@/i18n";
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: {
    isLoading: { type: Boolean, default: false },
    type: { type: String, required: true },
    personalizedOptions: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      defaultOptions: {
        chart: {
          type: null,
        },
        title: {
          text: null,
        },
        yAxis: null,
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
        },
        tooltip: {},
        legend: {},
        series: [],
        plotOptions: {},
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        lang: {
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },
      },
    };
  },
  computed: {
    completeOptions() {
      return { ...this.defaultOptions, ...this.personalizedOptions };
    },
  },
  watch: {
    "personalizedOptions.series": {
      handler() {
        this.resetSeriesVisibility();
      },
      deep: true,
    },
  },
  methods: {
    prepareSpLineOptions() {
      this.defaultOptions.chart.type = "spline";
      this.defaultOptions.plotOptions = {
        series: {
          marker: {
            lineWidth: 1,
            lineColor: null,
            symbol: "circle",
          },
          animation: {
            duration: 1000,
          },
          cursor: "pointer",
          connectNulls: true,
        },
      };
      this.defaultOptions.legend.symbolWidth = 10;
    },

    prepareBoxplotOptions() {
      this.defaultOptions.chart.type = "boxplot";
      this.defaultOptions.chart.inverted = true;
      this.defaultOptions.plotOptions = {
        boxplot: {
          fillColor: "#76D680",
          lineWidth: 1,
          lineColor: "#00000048",
          medianColor: "#00000048",
          medianWidth: 4,
          stemColor: "#00000048",
          stemWidth: 2,
          whiskerColor: "#00000048",
          whiskerLength: "25%",
          whiskerWidth: 3,
          maxPointWidth: 35,
          tooltip: {
            headerFormat: null,
            pointFormatter: function () {
              return (
                `<strong>${this.category}</strong>` +
                "<br/>" +
                `${i18n.t("TXT_MIN")}: ` +
                formatMonetary(this.low) +
                "<br/>" +
                `${i18n.t("TXT_FIRST_QUARTILE")}: ` +
                formatMonetary(this.q1) +
                "<br/>" +
                `${i18n.t("TXT_MEDIAN")}: ` +
                formatMonetary(this.median) +
                "<br/>" +
                `${i18n.t("TXT_THIRD_QUARTILE")}: ` +
                formatMonetary(this.q3) +
                "<br/>" +
                `${i18n.t("TXT_MAX")}: ` +
                formatMonetary(this.high) +
                "<br/>"
              );
            },
          },
        },
        scatter: {
          tooltip: {
            headerFormat: null,
            pointFormatter: function () {
              return `${i18n.t("TXT_OBSERVATION")}: ${formatMonetary(this.y)}`;
            },
          },
        },
      };
      this.defaultOptions.series = [{ data: [] }];
    },

    resolveType() {
      switch (this.type) {
        case "spline":
          this.prepareSpLineOptions();
          break;

        case "boxplot":
          this.prepareBoxplotOptions();
          break;
      }
    },

    resetSeriesVisibility() {
      this.$nextTick(() => {
        const chart = this.$refs.highchartsComponent?.chart;
        if (chart) {
          chart.series.forEach((serie) => {
            serie.setVisible(true, false);
          });
          chart.redraw();
        }
      });
    },
  },
  created() {
    this.resolveType();
  },
};
</script>
