<template>
  <div class="mt-4">
    <v-row no-gutters class="gap-4 mb-2">
      <v-col cols="2">
        <Prd-text-field
          v-model="model.min"
          :title="`${$t('TXT_MIN')} (%)`"
          :type="'number'"
          hide-spin-buttons
          :min="0"
        />
      </v-col>
      <v-col cols="2">
        <Prd-text-field
          v-model="model.max"
          :title="`${$t('TXT_MAX')} (%)`"
          :type="'number'"
          hide-spin-buttons
          :min="0"
        />
      </v-col>

      <v-col cols="4">
        <Prd-combo-box
          v-model="model.reference"
          :title="$t('TXT_ANALYSIS_COMPETITORS_PRICE')"
          :items="referenceItems"
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="end">
      <div class="competitor-size">
        <Prd-autocomplete-chip
          :originalValue="model.competitors"
          :title="$t('TXT_COMPETITOR') + ':'"
          :items="sellerList"
          :itemText="'value'"
          :externalDisabled="showAlert || sellerListLoading"
          :isLoading="sellerListLoading"
          :loading="sellerListLoading"
          @updates="model.competitors = $event"
          @searchInput="searchValue = $event"
        />
      </div>
      <div class="mb-2">
        <Prd-Tooltip-Informations
          v-if="showAlert && !sellerListLoading"
          :icon="'mdi-alert'"
          :sizeIcon="25"
          :colorIcon="'warning'"
          :text="$t('TXT_INFO_COMPETITORS')"
        />
      </div>
      <div class="ml-2 mt-2">
        <Prd-btn
          :title="$t('TXT_APPROVE_ALL')"
          :disabled="showAlert || !hasSellers"
          @click="addFilterSellers()"
        />
      </div>
    </v-row>
    <v-checkbox
      v-model="model.adjustedPrice"
      :label="$t('TXT_ADJUST_PRICE')"
      dense
      hide-details
    />
    <v-checkbox
      v-model="model.returnToLastPrice"
      :label="$t('TXT_RETURN_TO_LAST_PRICE_RENOVATION')"
      dense
      hide-details
    />
    <v-checkbox
      v-model="model.ignoreIfNull"
      :label="$t('TXT_IGNORE_PRODUCTS_WITHOUT_COMPETITORS')"
      dense
      hide-details
    />
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdAutocompleteChip from "@/components/common/prd-autocomplete-chip.vue";
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";

export default {
  props: ["values"],
  components: {
    PrdTextField,
    PrdAutocompleteChip,
    PrdTooltipInformations,
    PrdComboBox,
    PrdBtn,
  },
  data() {
    return {
      model: {
        min: 80,
        max: 120,
        adjustedPrice: false,
        returnToLastPrice: false,
        competitors: [],
        ignoreIfNull: false,
        reference: {
          text: this.$i18n.t("TXT_ANALYSIS_BY_AVERAGE_VALUE"),
          value: 0,
        },
      },
      referenceItems: [
        { text: this.$i18n.t("TXT_ANALYSIS_BY_AVERAGE_VALUE"), value: 0 },
        { text: this.$i18n.t("TXT_ANALYSIS_BY_FREQUENT_VALUE"), value: 1 },
      ],
      searchValue: null,
    };
  },

  computed: {
    sellerList() {
      return this.$store?.state?.pricingRuleV2.sellerList ?? [];
    },
    showAlert() {
      return !this.sellerList || this.sellerList.length == 0;
    },
    sellerListLoading() {
      return this.$store?.state?.pricingRuleV2?.sellerListLoading ?? false;
    },
    foundSellers() {
      return (
        this.sellerList.filter((competitor) =>
          competitor?.value
            ?.toLowerCase()
            .includes(this.searchValue?.toLowerCase())
        ) ?? []
      );
    },
    hasSellers() {
      return this.foundSellers.length > 0 && this.searchValue != "";
    },
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },

  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "MinRange",
          decimalValue: inputs.min ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: inputs.max ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "AdjustPrice",
          decimalValue: 0,
          booleanValue: inputs.adjustedPrice,
        },
        {
          stringValue: "BlockToLastPrice",
          decimalValue: 0,
          booleanValue: inputs.returnToLastPrice,
        },
        {
          stringValue: "IgnoreIfNull",
          decimalValue: 0,
          booleanValue: inputs.ignoreIfNull,
        },
        {
          stringValue: "ReferencePrice",
          decimalValue: inputs.reference.value ?? 0,
          booleanValue: false,
        },
      ];

      const competitors = this.prepareCompetitorsForUpdateConditions(inputs);
      formatedValue.push(...competitors);

      this.$emit("updateConditionValues", { formatedValue, conditionType: 15 });
    },
    prepareCompetitorsForUpdateConditions(inputs) {
      const competitors = structuredClone(inputs.competitors);
      return competitors.map((comp) => ({
        stringValue: comp.key,
        decimalValue: 0,
        booleanValue: false,
      }));
    },
    setInputs(conditionList) {
      const minRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.min = minRange;

      const maxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.max = maxRange;

      const AdjustPrice = conditionList.find(
        (condition) => condition.stringValue == "AdjustPrice"
      ).booleanValue;
      this.model.adjustedPrice = AdjustPrice;

      const BlockToLastPrice = conditionList.find(
        (condition) => condition.stringValue == "BlockToLastPrice"
      ).booleanValue;
      this.model.returnToLastPrice = BlockToLastPrice;

      const IgnoreIfNull = conditionList.find(
        (condition) => condition.stringValue == "IgnoreIfNull"
      ).booleanValue;
      this.model.ignoreIfNull = IgnoreIfNull;

      this.model.competitors = this.setCompetitors(conditionList);

      const ReferencePrice = conditionList.find(
        (condition) => condition.stringValue == "ReferencePrice"
      ).decimalValue;
      this.model.reference = ReferencePrice
        ? this.referenceItems.find((item) => item.value == ReferencePrice)
        : this.referenceItems[0];
    },
    setCompetitors(conditionList) {
      const filteredCondition = conditionList.filter((condition) =>
        condition.stringValue.includes("SELLERNAME")
      );
      const cloneArray = structuredClone(filteredCondition);
      const competitors = cloneArray.reduce((acc, comp) => {
        const foundedItem = this.sellerList.find(
          (compItemList) => compItemList.key === comp.stringValue
        );
        if (foundedItem) {
          acc.push(foundedItem);
        }
        return acc;
      }, []);
      return competitors;
    },
    addFilterSellers() {
      this.model.competitors.push(...structuredClone(this.foundSellers));
      this.model.competitors = this.removeDuplicates(this.model.competitors, item => item.value)
      this.searchValue = null;
    },
    removeDuplicates(array, keyExtractor) {
      const seen = new Set();
      return array.filter((item) => {
        const key = keyExtractor(item);
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>

<style lang="scss" scoped>
.competitor-size {
  min-width: 15% !important;
}
</style>
