<template>
  <v-row no-gutters class="gap-x-4 my-4" align="center">
    <prd-combo-box
      v-model="selected"
      :disabled="disabled"
      :items="computedFilters"
      :title="`${$t('TXT_FILTER_BY')} :`"
    />
  </v-row>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";

export default {
  props: ["filters", "disabled"],
  components: { prdComboBox },
  data() {
    return {
      selected: this.$i18n.t("TXT_ALL"),
    };
  },
  computed: {
    computedFilters() {
      const mappedFilters = this.filters.map((el) => ({
        text: el.label,
        value: el.dbField + ":" + el.value,
      }));

      return [{ text: this.$i18n.t("TXT_ALL"), value: "all" }, ...mappedFilters];
    }
  },
  methods: {
    setFilter(value) {
      if (value == "all") {
        this.$emit("getErpPreview", "", "");
        return;
      }

      const splitted = value.split(":");
      const [field, _value] = splitted;
      this.$emit("getErpPreview", field, _value);
    },
  },
  watch: {
    selected: {
      handler(value) {
        if (value) {
          this.setFilter(value.value);
        }
      },
    },
  },
};
</script>
