<template>
  <div>
    <v-icon
      :color="$prdStyles('color-warning')"
      @click.stop="handleDeleteRule"
      class="mr-2"
    >
      mdi-delete-outline
    </v-icon>

    <v-dialog
      v-model="isModalOpen"
      v-if="isModalOpen"
      max-width="600"
      persistent
    >
      <v-card class="pa-4" :disabled="deleteRuleLoading">
        <div class="text-end">
          <v-icon @click="isModalOpen = false" color="black">mdi-close</v-icon>
        </div>

        <div class="text-center">
          <v-icon color="#B00020">mdi-alert-outline</v-icon>
        </div>

        <h4 class="text-center my-4">
          {{ $i18n.t("TXT_EXCLUDE_RULE_GROUP_TITLE").toUpperCase() }}
        </h4>

        <p
          class="text-center mb-6"
          v-html="$t('TXT_EXCLUDE_RULE_GROUP_QUESTION')"
        />

        <div class="mb-6 py-4 px-2 contentBg">
          <div class="d-flex align-center gap-2">
            <p class="font-weight-bold ma-0 pa-0">
              {{ $i18n.t("TXT_GROUP_NAME") }}:
            </p>
            <span>{{ rule.description }}</span>
          </div>
          <div class="d-flex align-center gap-2">
            <p class="font-weight-bold ma-0 pa-0">
              {{ $i18n.t("TXT_ACTIVE") }}:
            </p>
            <span>{{
              rule.isActive ? this.$i18n.t("TXT_YES") : this.$i18n.t("TXT_NO")
            }}</span>
          </div>
        </div>

        <p style="color: #b00020" class="text-center">
          {{ $i18n.t("TXT_UNDO_ACTION") }}
        </p>

        <div class="d-flex justify-end">
          <Prd-btn
            :disabled="deleteRuleLoading"
            :title="$i18n.t('TXT_CANCEL')"
            @click="isModalOpen = false"
            color="#b00020"
            outlined
            class="mr-4"
          />
          <Prd-btn
            :loading="deleteRuleLoading"
            :disabled="deleteRuleLoading"
            :title="$i18n.t('TXT_EXCLUDE')"
            @click="deleteRule"
            color="#b00020"
            icon="mdi-delete-outline"
            leftIcon
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
export default {
  components: { PrdBtn },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    isGroupRules: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    isModalOpen: false,
    deleteRuleLoading: false,
  }),

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    handleDeleteRule() {
      if (this.isGroupRules) this.isModalOpen = true;
      else this.$emit("removeRuleFromList");
    },

    async deleteRule() {
      this.deleteRuleLoading = true;

      try {
        await Service.deleteRule(this.idCompany, this.rule?.idRule);
        this.$store.commit("removeRuleByid", this.rule?.idRule);
        this.isModalOpen = false;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_DELETED_RULES_SUCCESS"),
          type: "success",
        });
        this.$emit("ruleDeleted");
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_DELETED_RULES_ERROR"));
      }

      this.deleteRuleLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentBg {
  border-radius: 4px;
  background-color: $neutral-color-high-light;
}
</style>
