<template>
  <v-card class="pa-4 flex-grow-1">
    <v-row no-gutters justify="space-between" align="center">
      <h4>{{ $i18n.t("TXT_RULES_PRODUCTION").toUpperCase() }}</h4>

      <div>
        <router-link to="/main/create-pricing-rule-V2">
          <Prd-Btn
            :title="$t('TXT_ADD_RULE')"
            :icon="'mdi-plus'"
            class="mr-4"
          />
        </router-link>
        <Prd-btn
          v-if="!copilotIsOpen && enableCopilot"
          :title="$i18n.t('TXT_COPILOT')"
          :disabled="ruleListLoading"
          @click="$emit('openCopilot')"
        />
      </div>
    </v-row>

    <Prd-loading-circular
      v-if="ruleListLoading && !listRulesSkeletonLoader"
      size="60"
      class="mt-6"
    />
    <div class="mt-6">
      <Skeleton-loader
        v-if="showSkeletonLoader || listRulesSkeletonLoader"
        :ruleListLength="groupRulesList.length"
      />
    </div>

    <v-expansion-panels
      v-if="!ruleListLoading && !showSkeletonLoader && !listRulesSkeletonLoader"
      v-model="panels"
      multiple
      class="mt-6"
    >
      <draggable
        v-model="groupRulesList"
        @start="drag = true"
        @end="drag = false"
        @change="onRulesChanged"
        class="removed-draggable-style"
        handle=".handle"
      >
        <Group-Rule-Panel
          v-for="(rule, index) in groupRulesList"
          :rule="rule"
          :index="index"
          :key="index"
          @ruleDeleted="panels = []"
          class="mt-4"
        />
      </draggable>
    </v-expansion-panels>

    <h3
      v-if="!ruleListLoading && !groupRulesList.length"
      class="text-center mt-6"
    >
      {{ $i18n.t("TXT_NO_RULES_FOUNDED") }}
    </h3>

    <v-overlay v-if="ruleOrderLoading" color="#fff" z-index="100000">
      <v-progress-circular
        color="#3c5ca7"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import GroupRulePanel from "./components/group-rule-panel.vue";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
import SkeletonLoader from "./components/skeleton-loader.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: {
    PrdBtn,
    GroupRulePanel,
    PrdLoadingCircular,
    draggable,
    SkeletonLoader,
  },

  data() {
    return {
      ruleOrderLoading: false,
      showSkeletonLoader: false,
      panels: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    listRulesSkeletonLoader() {
      return this.$store.state.pricingRuleV2.listRulesSkeletonLoader;
    },

    groupRulesList: {
      get() {
        return this.$store.state.pricingRuleV2.groupRulesList;
      },
      set(value) {
        this.$store.commit("updateRuleList", value);
      },
    },

    ruleListLoading() {
      return this.$store.state.pricingRuleV2.ruleListLoading;
    },

    changedIdRules() {
      return this.$store?.state?.pricingRuleV2?.changedIdRules ?? [];
    },

    // COPILOT ENVIRONMENT
    copilotIsOpen() {
      return this.$store?.state?.copilotStore?.isOpen ?? false;
    },
    enableCopilot() {
      return this.$store.getters.enableCopilot;
    },
  },

  watch: {
    changedIdRules: {
      handler(value) {
        if (value.length) {
          const panelIndexes = this.groupRulesList
            .map((rule, index) => (value.includes(rule.idRule) ? index : null))
            .filter((index) => index !== null);

          this.panels.push(...panelIndexes);
        }
      },
    },
  },

  methods: {
    async onRulesChanged(event) {
      this.panels = [];
      this.showSkeletonLoader = true;

      if (event.moved) {
        const rule = this.groupRulesList[event.moved.newIndex];

        try {
          await Service.setOrder(
            this.idCompany,
            rule.idRule,
            event.moved.newIndex
          );

          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_CHANGE_RULE_ORDER_SUCCESS"),
            type: "success",
          });
        } catch (error) {
          this.$handleError(error, this.$i18n.t("TXT_CHANGE_RULE_ORDER_ERROR"));
        }

        await this.$store.dispatch("getRules", false);

        this.showSkeletonLoader = false;
      }
    },
  },

  created() {
    const showLoader = true;

    this.$store.dispatch("getRules", showLoader);
    this.$store.dispatch("getFilters");
    this.$store.dispatch("getSellers");
  },
};
</script>

<style lang="scss" scoped>
.removed-draggable-style {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}
</style>
